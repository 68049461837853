<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" :colon="false">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-date-picker
                style="width: 180px"
                placeholder="预定日期"
                v-model="date"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <div class="cards">
      <a-card class="">
        <div class="left">
          <div class="icon">
            <img src="./assets/icon1.png" alt="" />
          </div>
          <div class="statistic">
            <div class="label">{{ renderDate }}工作餐预定</div>
            <div>
              <span class="value">{{ lunchCount }}</span>
              <span class="unit">份</span>
            </div>
          </div>
        </div>
        <Padding />
        <a-table
          bordered
          :data-source="lunchList"
          :loading="loading"
          :pagination="false"
        >
          <a-table-column title="部门" data-index="deptName"> </a-table-column>
          <a-table-column title="预定份数" data-index="count" align="right">
          </a-table-column>
        </a-table>
      </a-card>
      <a-card class="">
        <div class="left">
          <div class="icon" style="background-color: rgb(255, 243, 231)">
            <img src="./assets/icon2.png" alt="" />
          </div>
          <div class="statistic">
            <div class="label">{{ renderDate }}加班餐预定</div>
            <div>
              <span class="value" style="color: #ff8c18">{{
                overtimeCount
              }}</span>
              <span class="unit">份</span>
            </div>
          </div>
        </div>
        <Padding />

        <a-table
          bordered
          :data-source="overtimeList"
          :loading="loading"
          :pagination="false"
        >
          <a-table-column title="部门" data-index="deptName"> </a-table-column>
          <a-table-column title="预定份数" data-index="count" align="right">
          </a-table-column>
        </a-table>
      </a-card>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/oa/meal";
import moment from "moment";
export default {
  data() {
    return {
      date: null,
      loading: false,
      list: [],
    };
  },

  mounted() {
    const now = new Date();
    if (now.getHours() < 12) {
      // 显示今天的数据
      this.date = moment();
    } else {
      // 显示明天的数据
      this.date = moment().add(1, "days");
    }
  },

  watch: {
    date(newValue) {
      if (newValue) {
        this.getList();
      }
    },
  },

  computed: {
    lunchList() {
      return this.list.filter((item) => item.type === "lunch");
    },
    overtimeList() {
      return this.list.filter((item) => item.type === "overtime");
    },
    lunchCount() {
      let sum = 0;
      for (let i = 0; i < this.lunchList.length; i++) {
        const count = this.lunchList[i].count || 0;
        sum += count;
      }
      return sum;
    },
    overtimeCount() {
      let sum = 0;
      for (let i = 0; i < this.overtimeList.length; i++) {
        const count = this.overtimeList[i].count || 0;
        sum += count;
      }
      return sum;
    },

    renderDate() {
      if (!this.date) {
        return "";
      }
      return this.date.format("YYYY-MM-DD");
    },
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        date: this.date.format("YYYY-MM-DD"),
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 8px 8px;
  gap: 8px;
}

.icon {
  height: 56px;
  width: 56px;
  background-color: rgb(231, 244, 255);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  img {
    width: 48px;
  }
}

.statistic {
  .label {
    font-size: 16px;
  }
  .value {
    color: #1890ff;
    font-size: 24px;
    padding-right: 4px;
  }
}
</style>